import $ from 'jquery';
import './js/vendor/bootstrap-datepicker.min';
import './css/vendor/bootstrap/bootstrap.min.css';
import './css/vendor/bootstrap/login.css';
import './css/vendor/bootstrap/bootstrap-datepicker3.min.css';
import './scss/admin.scss';

$('.js-datepicker').datepicker({
    format: 'dd/mm/yyyy'
});
